import { Redirect, Route, Switch } from "react-router-dom";
import { mdiAccountCircle, mdiAccountMultiple } from "@mdi/js";
import AccountOverview from "src/pages/account/AccountOverview";
import ForgotPassword from "src/pages/account/ForgotPassword";
import SetPassword from "src/pages/account/SetPassword";
import SignIn from "src/pages/account/SignIn";
import { log } from "./logger";

import { TeamList } from "src/pages/groups/GroupList";
import { useAuth } from "./context/AuthProvider";
import { isAdmin } from "./utils";
import { TeamDetail } from "src/pages/groups/GroupDetail";
import { TeamMemberDetail } from "src/pages/groups/GroupMemberDetail";

const PUBLIC_ROUTES_CONFIG = [
  {
    path: "/",
    exact: true,
    component: (props) => (
      <Redirect to={{ pathname: "/signin", search: props.location.search }} />
    ),
  },
  { path: "/signin", component: SignIn },
  { path: "/forgotpassword", component: ForgotPassword },
  { path: "/setpassword", component: SetPassword },
];

const ADMIN_PRIVATE_ROUTES_CONFIG = [
  { path: "/", exact: true, component: () => <TeamList /> },
  { path: "/groups/:id", exact: true, component: () => <TeamDetail /> },
  {
    path: "/groups/:id/members/:id",
    exact: true,
    component: () => <TeamMemberDetail />,
  },
  {
    path: "/account",
    exact: true,
    component: () => <Redirect to="/account/overview" />,
  },
  { path: "/account/overview", component: AccountOverview },
];

const STANDARD_PRIVATE_ROUTES_CONFIG = [
  { path: "/", exact: true, component: () => <TeamList /> },
  {
    path: "/account",
    exact: true,
    component: () => <Redirect to="/account/overview" />,
  },
  { path: "/account/overview", component: AccountOverview },
];

const ADMIN_SIDEBAR_NAVIGATION_CONFIG = [
  { to: "/", title: "Groups", icon: mdiAccountMultiple, exact: true },
  { to: "/account", title: "Account", icon: mdiAccountCircle },
];

const STANDARD_SIDEBAR_NAVIGATION_CONFIG = [
  { to: "/", title: "Groups", icon: mdiAccountMultiple, exact: true },
  { to: "/account", title: "Account", icon: mdiAccountCircle },
];

const STANDARD_ACCOUNT_NAVIGATION_CONFIG = [
  { to: "/account/overview", title: "Overview & Security" },
];

function RouteWithSubRoutes(route) {
  if (route.event) {
    log(route.event);
  }

  return (
    <Route component={route.component} exact={route.exact} path={route.path} />
  );
}

function PublicRoutesForApp() {
  const routes = getRoutesWith404(PUBLIC_ROUTES_CONFIG);

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
}

function PrivateRoutesForApp() {
  const { currentEmployee } = useAuth();
  const routes = getPrivateRoutes(isAdmin(currentEmployee));

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
}

function getPrivateRoutes(isAdmin: boolean) {
  if (isAdmin) {
    return getRoutesWith404(ADMIN_PRIVATE_ROUTES_CONFIG);
  }
  return getRoutesWith404(STANDARD_PRIVATE_ROUTES_CONFIG);
}

function getRoutesWith404(routes) {
  return [...routes, { component: () => <Redirect to="/" /> }];
}

function getAccountRoutes() {
  return STANDARD_ACCOUNT_NAVIGATION_CONFIG;
}

function getSidebarRoutes(isAdmin: boolean) {
  if (isAdmin) {
    return ADMIN_SIDEBAR_NAVIGATION_CONFIG;
  }
  return STANDARD_SIDEBAR_NAVIGATION_CONFIG;
}

export {
  PrivateRoutesForApp,
  PublicRoutesForApp,
  getAccountRoutes,
  getSidebarRoutes,
};
