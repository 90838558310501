import styled from "styled-components";
import { media } from "src/shared/breakpoints";
import { colors } from "src/shared/colors";

export const SignInContainer = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 1rem;
  color: ${colors.BLACK};

  ${media.phone} {
    justify-content: space-between;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 65%;
    display: flex;
    align-self: center;
    margin-bottom: 2rem;
    ${media.phone} {
      margin-bottom: 2rem;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.5rem;

  ${media.phone} {
    flex-direction: column-reverse;
    justify-content: space-between;
    flex: auto;
    max-height: 25%;
    min-height: 115px;
    a {
      width: 100%;
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: calc(100% - 5px);

  a,
  button {
    width: 100%;
    height: 100%;
  }

  ${media.phone} {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1rem;

  a {
    margin: 0;
  }
`;
