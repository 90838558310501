import React from "react";
import { AvatarContainer, Square, Details } from "./index.styles";
import { H3, P2 } from "./../Text/.";

const Avatar = ({ ...props }) => {
  return (
    <AvatarContainer>
      <Square {...props}>
        <H3>{props.initials}</H3>
      </Square>
      {props.small ? (
        <Details>
          <H3>{props.fullName ?? props.email}</H3>
        </Details>
      ) : (
        <Details>
          <H3>{props.fullName}</H3>
          <P2 data-testid="avatarContainerEmail">{props.email}</P2>
        </Details>
      )}
    </AvatarContainer>
  );
};

export default Avatar;
