import { Flex, HStack } from "@chakra-ui/react";
import { VisibilityState, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { MdOpenInNew } from "react-icons/md";
import { Link } from "react-router-dom";
import { BillingGroup, TeamStatus, listBillingGroups } from "src/shared/api";
import { Button } from "src/shared/components/Button";
import { ModalAddGroup } from "src/shared/components/ModalAddGroup";
import { Table } from "src/shared/components/Table";
import { SearchProvider } from "src/shared/components/Table/SearchContext";
import { useModal } from "src/shared/context/ModalProvider";
import { makeStripeCustomerLink } from "src/shared/utils";

export type BillingGroupRow = {
  id: string;
  name: string;
  status: string;
  stripeCustomerId: string;
  members: string;
  actions: string;
};

export const TeamList = () => {
  const { openModal } = useModal();
  const [billingGroups, setBillingGroups] = useState<BillingGroup[]>([]);
  const [loading, setLoading] = useState(true);

  const teamRows = useMemo<BillingGroupRow[]>(() => {
    return billingGroups?.map((billingGroup) => ({
      id: billingGroup.id.toString(),
      name: billingGroup.name,
      stripeCustomerId: billingGroup.stripeCustomerId,
      status: TeamStatus.Active,
      members: billingGroup.memberCount.toString(),
      actions: "",
    }));
  }, [billingGroups]);

  useEffect(() => {
    (async () => {
      const billingGroups = await listBillingGroups();
      setBillingGroups(billingGroups.data);
      setLoading(false);
    })();
  }, []);

  const columnHelper = createColumnHelper<BillingGroupRow>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        minSize: 150,
        cell: (cell) => (
          <Link to={`/groups/${cell.row.original.id}`}>
            <Flex alignItems="center" gap="1" fontWeight="bold">
              {cell.getValue()}
            </Flex>
          </Link>
        ),
      }),
      columnHelper.accessor("members", {
        header: "Members",
        minSize: 150,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        minSize: 150,
      }),
      columnHelper.accessor("actions", {
        header: "Actions",
        minSize: 150,
        cell: (cell) => (
          <Flex justifyContent="flex-end" gap="3">
            <Link to={`/groups/${cell.row.original.id}`}>Edit</Link>
            <a
              href={makeStripeCustomerLink(cell.row.original.stripeCustomerId)}
              target="_blank"
              rel="noreferrer"
            >
              <Flex align="center" gap="1">
                Stripe <MdOpenInNew />
              </Flex>
            </a>
          </Flex>
        ),
      }),
    ],
    [columnHelper]
  );

  const initialColumnVisibility: Record<keyof BillingGroupRow, boolean> &
    VisibilityState = {
    id: false,
    name: true,
    status: true,
    members: true,
    stripeCustomerId: false,
    actions: true,
  };

  return (
    <SearchProvider>
      <Table
        title="Groups"
        data={teamRows}
        loading={loading}
        columns={columns as any}
        rightActions={
          <HStack>
            <Button
              onClick={() => openModal(<ModalAddGroup />)}
              secondary
              size="sm"
            >
              Add Group
            </Button>
          </HStack>
        }
        initialColumnVisibility={initialColumnVisibility}
      />
    </SearchProvider>
  );
};
