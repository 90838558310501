import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import Input from "../Input";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { createBillingGroup } from "src/shared/api";
import { toast } from "sonner";

export const ModalAddGroup = () => {
  const { closeModal } = useModal();
  const history = useHistory();
  const [name, setName] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const title = "Add Group";

  const createGroup = async () => {
    if (!name) return;

    try {
      setLoading(true);
      const billingGroup = await createBillingGroup(name);
      closeModal();
      history.push(`/groups/${billingGroup.id}`);
      toast.success("Group created successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to create group");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={title}
      dialogue={{
        primary: {
          onClick: createGroup,
          title: "Add Group",
          autoFocus: true,
          loading,
        },
        secondary: { onClick: closeModal },
      }}
    >
      <Input
        value={name}
        type="text"
        placeholder="Name"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            createGroup();
          }
        }}
        onChange={(e) => setName(e.target.value)}
      />
    </Modal>
  );
};
