import React from "react";
import { colors } from "src/shared/colors";
import { SpinnerContainer } from "./index.styles";
import { CircularProgress } from "@chakra-ui/react";

const LoadingSpinner = ({ ...props }) => {
  return (
    <SpinnerContainer {...props} data-testid="loading-spinner">
      <CircularProgress
        color={props.frontColor || colors.BLUE}
        size={16}
        isIndeterminate
      />
    </SpinnerContainer>
  );
};

export default LoadingSpinner;
