import { API } from "aws-amplify";

type PaginatedResponse<T> = {
  data: T[];
  totalCount: number;
  hasMore: boolean;
};

export enum TeamStatus {
  Active = "active",
  Inactive = "inactive",
}

export type TeamMember = {
  id: number;
  teamId: number;
  name: string;
  linesUsed: number;
  linesCapacity: number;
  subscriptionElements: TeamMemberSubscriptionElement[];
  createdOn: string;
  updatedOn: string;
};

type TeamMemberSubscriptionElement = {
  id: number;
  teamMemberId: number;
  subscriptionElementId: number;
  name: string;
  createdOn: string;
  updatedOn: string;
};

export type BillingGroup = {
  id: number;
  name: string;
  stripeCustomerId: string;
  memberCount: number;
  createdOn: string;
  updatedOn: string;
};

type ListBillingGroupsInput = {
  afterId?: number;
  limit?: number;
};

export enum EmployeeRole {
  Admin = "admin",
  Editor = "editor",
  Transcriber = "transcriptionist",
}

export type Employee = {
  id: number;
  avatarUri: string | null;
  role: EmployeeRole;
  cognitoId: string | null;
  firstName: string | null;
  lastName: string | null;
  dailyLineCountGoal: number | null;
  dailyLineCount: number | null;
  createdOn: string;
  updatedOn: string;
};

export type User = {
  id: number;
  firstName: string | null;
  lastName: string | null;
};

export const billingIntervalMap: Record<BillingElementInterval, string> = {
  MONTH: "Monthly",
  QUARTER: "Quarterly",
  YEAR: "Yearly",
};

export enum BillingElementInterval {
  Month = "MONTH",
  Quarter = "QUARTER",
  Year = "YEAR",
}

export type BillingPrice = {
  id: number;
  label: string;
  line_allowance?: number;
  interval?: string;
  overage_interval?: string;
  stripe_price_id: string;
  stripe_product_id: string;
  overage_stripe_price_id?: string;
  overage_stripe_product_id?: string;
  created_on: Date;
  updated_on: Date;
};

export type BillingElement = {
  id: number;
  billingGroupMemberId: number;
  billingPriceId: number;
  lineAllowance: number;
  label: string;
  interval: BillingElementInterval;
  overageInterval: BillingElementInterval;
  createdOn: string;
  updatedOn: string;
};

export type BillingGroupMember = {
  id: number;
  billingGroupId: number;
  userId: number;
  user: User | null;
  billingElements: BillingElement[];
  createdOn: string;
  updatedOn: string;
};

const mapSnakeToCamel = (obj: any) => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(mapSnakeToCamel);
  }
  if (typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = key.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace("-", "").replace("_", "");
      });
      return {
        ...acc,
        [camelKey]: mapSnakeToCamel(obj[key]),
      };
    }, {});
  }
  return obj;
};

const mapCamelToSnake = (obj: any) => {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(mapCamelToSnake);
  }
  if (typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeKey = key.replace(/([A-Z])/g, ($1) => {
        return "_" + $1.toLowerCase();
      });
      return {
        ...acc,
        [snakeKey]: mapCamelToSnake(obj[key]),
      };
    }, {});
  }
  return obj;
};

export const getBillingGroup = async (id: string): Promise<BillingGroup> => {
  const response = await API.get("SCRIBE_API", `/billing_groups/${id}`, {});
  return mapSnakeToCamel(response);
};

export const getBillingGroupMember = async (
  id: string
): Promise<BillingGroupMember> => {
  const response = await API.get(
    "SCRIBE_API",
    `/billing_group_members/${id}`,
    {}
  );
  return mapSnakeToCamel(response);
};

export const listBillingGroups = async ({
  afterId,
  limit,
}: ListBillingGroupsInput | undefined = {}): Promise<
  PaginatedResponse<BillingGroup>
> => {
  const response = await API.get("SCRIBE_API", "/billing_groups", {
    queryStringParameters: mapCamelToSnake({
      afterId,
      limit: limit ?? 10,
    }),
  });
  return mapSnakeToCamel(response);
};

export const createBillingGroup = async (
  name: string
): Promise<BillingGroup> => {
  const response = await API.post("SCRIBE_API", "/billing_groups", {
    body: mapCamelToSnake({ name }),
  });
  return mapSnakeToCamel(response);
};

export const createBillingGroupMember = async (
  userId: number,
  billingGroupId: number
): Promise<BillingGroupMember> => {
  const response = await API.post("SCRIBE_API", "/billing_group_members", {
    body: mapCamelToSnake({ userId, billingGroupId }),
  });
  return mapSnakeToCamel(response);
};

export const createBillingElement = async (
  billingGroupMemberId: number,
  billingPriceId: number
): Promise<BillingElement> => {
  const response = await API.post("SCRIBE_API", "/billing_elements", {
    body: mapCamelToSnake({ billingGroupMemberId, billingPriceId }),
  });
  return mapSnakeToCamel(response);
};

export const deleteBillingElement = async (
  id: number
): Promise<BillingElement> => {
  const response = await API.del("SCRIBE_API", `/billing_elements/${id}`, {});
  return mapSnakeToCamel(response);
};

export const deleteBillingGroupMember = async (
  id: number
): Promise<BillingGroupMember> => {
  const response = await API.del(
    "SCRIBE_API",
    `/billing_group_members/${id}`,
    {}
  );
  return mapSnakeToCamel(response);
};

export const deleteBillingGroup = async (id: number): Promise<BillingGroup> => {
  const response = await API.del("SCRIBE_API", `/billing_groups/${id}`, {});
  return mapSnakeToCamel(response);
};

export const listBillingGroupMembers = async (
  billingGroupId: string
): Promise<PaginatedResponse<BillingGroupMember>> => {
  const response = await API.get(
    "SCRIBE_API",
    `/billing_group_members?billing_group_id=${billingGroupId}`,
    {}
  );
  return mapSnakeToCamel(response);
};

export const listBillingPrices = async (): Promise<
  PaginatedResponse<BillingPrice>
> => {
  const response = await API.get("SCRIBE_API", `/billing_prices?limit=100`, {});
  return mapSnakeToCamel(response);
};

export const getEmployee = async (): Promise<Employee> => {
  const response = await API.get("SCRIBE_API", "/employees/me", {});
  return mapSnakeToCamel(response);
};
