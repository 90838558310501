const colors = Object.freeze({
  BLACK_A05: 'rgba(0, 0, 0, 0.05)',
  BLACK_A20: 'rgba(0, 0, 0, 0.2)',
  BLACK_A40: 'rgba(0, 0, 0, 0.4)',
  BLACK_A50: 'rgba(0, 0, 0, 0.5)',
  BLACK: '#2A414C',
  BLUE_200: '#4DB1F0',
  BLUE_300: '#2A9FD6',
  BLUE: '#4DB1F0',
  GREEN: '#3ED06F',
  GREEN_A05: 'rgba(62, 208, 111, 0.05)',
  GREY_100: '#ebeced',
  GREY_200: '#E5E6E8',
  GREY_300: '#B2B7BC',
  GREY_700: '#737B83',
  GREY_800: '#646E78',
  GREY_900: '#404D59',
  GREY: '#C7CACC',
  ORANGE: '#F98E2A',
  PINK: '#DF37BA',
  PURPLE: '#6E4DF0',
  RED: '#EE4949',
  TEAL: '#2AE0EC',
  TRANSPARENT: 'transparent',
  WHITE: '#FFFFFF',
  YELLOW: '#F8D353'
})

export {
  colors
}
