import * as Sentry from '@sentry/browser'
import env, { isNotRunningLocally } from './envConfig'
import {EventKind} from './events'

const log = (kind: EventKind, data?: object) => {
  if (isNotRunningLocally()) {
    Sentry.addBreadcrumb({
      type: kind.toString()
    })
  } else {
    console.info(kind, data) // eslint-disable-line
  }
}

const setupLogger = () => {
  if (isNotRunningLocally()) {
    Sentry.init({ dsn: env.SENTRY.DSN })
  }
}

const logUserSignedIn = (user) => {
  if (isNotRunningLocally()) {
    Sentry.configureScope(scope => {
      scope.setUser({ id: user?.id })
    })
  }
}

const logUserSignedOut = () => {
  log(EventKind.UserSignedOut)
}

export {
  log,
  logUserSignedIn,
  logUserSignedOut,
  setupLogger
}
