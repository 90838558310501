import React, { createContext, useState, useContext, useEffect } from 'react'
import ReactDOM from 'react-dom'

interface ModalContextProps {
  modal?: React.ReactNode
  openModal: (modal: React.ReactNode) => void
  closeModal: () => void
}

export const ModalContext = createContext<ModalContextProps>({
  modal: null,
  openModal: () => {},
  closeModal: () => {}
})

export const ModalContainer = () => {
  const { modal, closeModal } = useModal()
  
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') closeModal()
    }
    
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [closeModal])

  return modal ? ReactDOM.createPortal(modal, document.body) : null
}

const ModalProvider = (props) => {
  const [modal, setModal] = useState<React.ReactNode | null>(null)

  const closeModal = () => setModal(null)
  const openModal = (modal: React.ReactNode) => setModal(modal)

  return (
    <ModalContext.Provider
      value={{
        modal,
        openModal,
        closeModal
      }}
      {...props} />
  )
}

export const useModal = () => useContext(ModalContext)

export default ModalProvider
