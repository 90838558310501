import React from "react";
import {
  ModalBackground,
  ModalContainer,
  ModalTitle,
  TitleWrapper,
  ModalBody,
  ModalDialogue,
  ModalBackgroundContainer,
} from "./index.styles";
import { Button } from "../Button";
import { useModal } from "src/shared/context/ModalProvider";
import { IconButton } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";

interface ModalProps {
  title: string;
  onClose?: () => void;
  dialogue?: {
    secondary?: {
      title?: string;
      onClick?: () => void;
      loading?: boolean;
    };
    primary?: {
      autoFocus?: boolean;
      title?: string;
      onClick?: () => void;
      loading?: boolean;
    };
  };
  children: React.ReactNode;
}

function Modal(props: ModalProps) {
  const { closeModal } = useModal();
  const {
    secondary: dialogueSecondaryAction = {},
    primary: dialoguePrimaryAction = {},
  } = props.dialogue || {};

  const onClose = props.onClose || closeModal;

  return (
    <ModalBackground>
      <ModalBackgroundContainer>
        <ModalContainer {...props}>
          <TitleWrapper>
            <ModalTitle>{props.title}</ModalTitle>
            <IconButton
              onClick={onClose}
              sx={{ marginRight: "-0.5rem" }}
              aria-label="close"
              variant="ghost"
            >
              <MdClose />
            </IconButton>
          </TitleWrapper>
          <ModalBody>{props.children}</ModalBody>
          {props.dialogue && (
            <ModalDialogue>
              {props.dialogue.secondary && (
                <Button
                  {...dialogueSecondaryAction}
                  data-testid="dialogue-secondary-action"
                  secondary
                >
                  {dialogueSecondaryAction.title || "Cancel"}
                </Button>
              )}
              {props.dialogue.primary && (
                <Button
                  autoFocus={dialoguePrimaryAction.autoFocus}
                  {...dialoguePrimaryAction}
                  data-testid="dialogue-primary-action"
                >
                  {dialoguePrimaryAction.title || "Save"}
                </Button>
              )}
            </ModalDialogue>
          )}
        </ModalContainer>
      </ModalBackgroundContainer>
    </ModalBackground>
  );
}

Modal.defaultProps = {
  secondaryButtonText: "Cancel",
  actionButtonText: "Save",
};

export default Modal;
