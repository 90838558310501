import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import { useEffect, useState } from "react";
import {
  BillingGroupMember,
  BillingPrice,
  billingIntervalMap,
  createBillingElement,
  listBillingPrices,
} from "src/shared/api";
import { toast } from "sonner";
import { Select } from "@chakra-ui/react";

export const ModalAddElement = ({
  billingGroupMember,
  onSave,
}: {
  billingGroupMember: BillingGroupMember | null;
  onSave?: () => void;
}) => {
  const { closeModal } = useModal();
  const [priceId, setPriceId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [billingPrices, setBillingPrices] = useState<BillingPrice[]>([]);

  const title = "Add Subscription Element";

  useEffect(() => {
    (async () => {
      const billingPricesResponse = await listBillingPrices();
      setBillingPrices(billingPricesResponse.data);
    })();
  }, []);

  const createGroup = async () => {
    if (!priceId || !billingGroupMember?.id) return;

    try {
      setLoading(true);
      await createBillingElement(billingGroupMember.id, parseInt(priceId));
      closeModal();
      onSave?.();
      toast.success("Element added successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to add element");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={title}
      dialogue={{
        primary: {
          onClick: createGroup,
          title: "Add Element",
          autoFocus: true,
          loading,
        },
        secondary: { onClick: closeModal },
      }}
    >
      <Select
        onChange={(e) => {
          setPriceId(e.target.value);
        }}
        placeholder="Select option"
      >
        {billingPrices?.map((price) => (
          <option key={price.id} value={price.id}>
            {price.label} ({billingIntervalMap[price?.interval ?? ""]})
          </option>
        ))}
      </Select>
    </Modal>
  );
};
