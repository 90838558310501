import { StyledButtonProps, StyledNavLinkButton } from "./index.styles";

import Icon from "@mdi/react";
import {
  Button as CButton,
  ButtonProps as CButtonProps,
} from "@chakra-ui/react";
import React from "react";
import { colors } from "src/shared/colors";

interface ButtonProps extends StyledButtonProps {
  iconName?: string;
  iconColor?: string;
  iconSize?: number;
  children?: any;
  disabled?: boolean;
  loading?: boolean;
  [x: string]: any;
}

const InnerButton = ({
  iconName,
  iconColor,
  iconSize,
  children,
}: ButtonProps) => {
  return (
    <React.Fragment>
      {iconName && (
        <Icon
          className="mainIcon"
          color={iconColor}
          path={iconName}
          size={iconSize || 1}
        ></Icon>
      )}
      <span
        style={
          iconName && children
            ? { marginLeft: "0.6rem" }
            : { marginLeft: "0rem" }
        }
      >
        {children}
      </span>
    </React.Fragment>
  );
};

export const Button = ({
  iconName,
  iconColor,
  children,
  iconSize,
  loading,
  onClick,
  disabled,
  ...props
}: ButtonProps & CButtonProps) => {
  return (
    <CButton
      onClick={!disabled && !loading ? onClick : undefined}
      onKeyUp={(e) => {
        if (e.key === "Enter" && !disabled && !loading) {
          (onClick as any)?.();
        }
      }}
      borderRadius="lg"
      disabled={disabled}
      isLoading={loading}
      bg={props.secondary ? colors.GREY_200 : colors.BLUE}
      color={props.secondary ? colors.BLACK : colors.WHITE}
      _hover={{
        bg: props.secondary ? colors.GREY_300 : colors.BLUE_300,
      }}
      {...props}
    >
      {children}
    </CButton>
  );
};

export const LinkButton = ({
  iconName,
  iconColor,
  children,
  ...props
}: ButtonProps) => {
  return <CButton {...props}>{children}</CButton>;
};

export const NavLinkButton = ({
  iconName,
  iconColor,
  iconSize,
  children,
  ...props
}: ButtonProps) => {
  return (
    <StyledNavLinkButton {...props}>
      <InnerButton
        children={children}
        iconColor={iconColor}
        iconName={iconName}
        iconSize={iconSize}
      />
    </StyledNavLinkButton>
  );
};
