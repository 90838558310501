import React, { useContext, useState } from "react";
import { ColumnFiltersState, OnChangeFn } from "@tanstack/react-table";

interface SearchContextProps {
  setGlobalQuery: (query: string) => void;
  setColumnFilters: OnChangeFn<ColumnFiltersState>;
  globalQuery?: string;
  columnFilters?: ColumnFiltersState;
}

const SearchContext = React.createContext<SearchContextProps>({
  setGlobalQuery: () => console.warn("no search provider"),
  setColumnFilters: () => console.warn("no search provider"),
});

export const SearchProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [globalQuery, setGlobalQuery] = useState<string>('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  return (
    <SearchContext.Provider
      value={{
        globalQuery,
        setGlobalQuery,
        columnFilters,
        setColumnFilters,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  return useContext(SearchContext);
};