import { useAuth } from "src/shared/context/AuthProvider";
import {
  LinesSection,
  Section,
  SubSection,
  Column,
  Divider,
} from "./index.styles";
import { H3, Caption } from "src/shared/components/Text";
import { Button } from "src/shared/components/Button";
import Avatar from "src/shared/components/Avatar";
import { PageLayout } from "src/shared/components/PageLayout";
import { Icon } from "@chakra-ui/react";
import { MdLogout } from "react-icons/md";

function AccountOverview() {
  const { signOut, currentUser, currentEmployee } = useAuth();

  const fullName = `${currentEmployee?.firstName} ${currentEmployee?.lastName}`;

  return (
    <PageLayout title="Account">
      <LinesSection>
        <Avatar
          fullName={fullName}
          email={currentUser?.attributes?.email}
          initials={fullName[0]}
        />
      </LinesSection>
      <Section>
        <Divider />
        <Column>
          <SubSection>
            <H3>Security</H3>
            <Caption>
              Manage your account security by taking action below. If you need
              more help, contact an admin.
            </Caption>
            <Button
              data-testid="signOut"
              onClick={signOut}
              secondary
              mt="3"
              rightIcon={<Icon as={MdLogout} />}
            >
              Sign out
            </Button>
          </SubSection>
        </Column>
      </Section>
    </PageLayout>
  );
}

export default AccountOverview;
