import styled, { css } from "styled-components";
import MaskInput from "react-text-mask";

import { colors } from "src/shared/colors";

interface InputProps {
  error?: string;
  [key: string]: any;
}

const sharedInput = (props: InputProps) => `
    font-size: 1rem;
    padding: 0.75rem;
    display: flex;
    outline: none;
    border-radius: 10px;
    border: 1px solid ${colors.GREY};
    transition: all .2s ease;
    flex: 1;
    width: 100%;

    &:focus {
        box-shadow: 0 0 0 1px ${colors.BLUE};
        border: 1px solid ${colors.BLUE};
    }
    &::-webkit-input-placeholder {
        color: ${colors.GREY};
    }

    ${
      props.error &&
      css`
        border-color: ${colors.RED};
      `
    }
`;

export const StyledInput = styled.input<InputProps>`
  ${(props) => sharedInput(props)}
`;

export const StyledMaskInput = styled(MaskInput)<InputProps>`
  ${(props) => sharedInput(props)}
`;

export const StyledSelect = styled.select<InputProps>`
  ${(props) => sharedInput(props)}
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  width: 100%;
  gap: 0.25rem;
`;

export const Error = styled.div`
  font-size: 0.9rem;
  margin-top: 0.25rem;
  color: ${colors.RED};
`;

export const ErrorContainer = styled.div`
  width: 100%;
`;

export const RevealContainer = styled.div`
  display: flex;
  position: relative;
`;

export const RevealAction = styled.div`
  position: absolute;
  justify-content: center;
  transform: translateY(-50%);
  top: calc(50% + 3px);
  display: flex;
  align-items: center;
  right: calc(0.5rem + 2px);
  padding: 0.15rem;
  user-select: none;
  border-radius: 50%;
  width: calc(2rem - 2px);
  height: calc(2rem - 2px);
  transition: 0.25s ease;
  opacity: 0.5;

  svg {
    margin-bottom: 0 !important;
  }

  :hover {
    opacity: 1;
    cursor: pointer;
  }
`;
