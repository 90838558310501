import styled, { css } from 'styled-components'
import { media } from 'src/shared/breakpoints'
import { colors } from 'src/shared/colors'

export const StyledNotificationsContainer = styled.div`
    position: fixed;
    width: calc(100vw - 2rem);
    height: calc(100vh - 2rem);
    top: 1rem;
    left: 1rem;
    pointer-events: none;
    z-index: 99999999;

    ${media.phone} {
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
    }

    * {
      pointer-events: all;
    }
`

interface StyledNotificationProps {
  position?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'bottomCenter' | 'topCenter'
}

export const StyledNotification = styled.div<StyledNotificationProps>`
    font-weight: 300;
    padding: 0.75rem;
    border-radius: 1.5rem;
    background: ${colors.BLACK};
    color: ${colors.WHITE};
    position: absolute;
    display: flex;
    box-shadow: 0px 0.5rem 1rem ${colors.BLACK_A20};
    max-width: 400px;
    min-width: 315px;
    animation-duration: 0.2s;
    animation-name: slidein;

    @keyframes slidein {
      0% {
        transform: translateY(200%);
      }
    
      100% {
        transform: translateY(0%);
      }
    }
    
    ${media.phone} {
        max-width: calc(100% - 2rem);
        min-width: calc(100% - 2rem);
    }
    
  ${props => props.position === 'topLeft' && css`
    top: 0;
    left: 0;
    margin: 1rem 1rem 0;
  `}

  ${props => props.position === 'topRight' && css`
    top: 0;
    right: 0;
    margin: 1rem 1rem 0;
  `}

  ${props => props.position === 'topCenter' && css`
      top: 0;
      margin: 1rem 1rem 0;
  `}

  ${props => props.position === 'bottomLeft' && css`
    bottom: 0;
    left: 0;
    margin: 0 1rem 1rem;
  `}

  ${props => props.position === 'bottomRight' && css`
    bottom: 0;
    right: 0;
    margin: 0 1rem 1rem;
  `}

  ${props => props.position === 'bottomCenter' && css`
    bottom: 0;
    margin: 0 1rem 1rem;
  `}
`

export const HeaderSection = styled.div`
    justify-content: flex-start;
    display: flex;
    width: 100%;
    font-size: 1rem;
    align-items: end;

    svg{
        min-width: 1.5rem;
        min-height: 1.5rem;
        display: flex;
    }

    .mainIcon {
        margin-right: .5rem;
    }
`

export const NotificationTitle = styled.p`
    margin: 0 .75rem 0 0;
    display: flex;
    align-self: center;
`

interface NotificationActionProps {
  loading?: boolean
}

export const NotificationAction = styled.button<NotificationActionProps>`
    outline: none;
    text-align: center;
    padding: 0;
    background: none;
    border: none;
    text-decoration: none;
    font-size: 1rem;
    letter-spacing: .03rem;
    margin-left: auto;
    font-weight: 600;
    color: ${colors.BLUE};
    text-transform: uppercase;
    height: 1.5rem;
    transition: .25s ease;

    :hover {
        cursor: pointer;
        opacity: .6;

        ${props => props.loading && css`
          cursor: default;
          opacity: 1;
        `}
    }

`
