import React, { useState } from "react";
import { H1 } from "src/shared/components/Text";
import Input from "src/shared/components/Input";
import { isValidEmail } from "src/shared/validators";
import { Button, LinkButton } from "src/shared/components/Button";
import { ForgotPasswordContainer, InputContainer } from "./index.styles";

import LoadingSpinner from "src/shared/components/LoadingSpinner";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useNotification } from "src/shared/context/NotificationProvider";

function ForgotPassword(props) {
  const initialFieldState = { email: "" };
  const [field, setField] = useState(initialFieldState);
  const [loading, setLoading] = useState(false);
  const [forgotPasswordEmailSent, setForgotPasswordEmailSent] = useState(false);
  const { sendSuccessNotification, sendErrorNotification } =
    useNotification();

  const updateField = (event) => {
    setField({ ...field, [event.target.name]: event.target.value });
  };

  const requestPasswordRecovery = async () => {
    if (isValidEmail(field.email)) {
      setLoading(true);

      try {
        await Auth.forgotPassword(field.email);
        setLoading(false);
        sendSuccessNotification({ title: "Email has been sent!" });
        setForgotPasswordEmailSent(true);
      } catch (error) {
        setLoading(false);

        if ((error as any)?.code === "LimitExceededException") {
          sendErrorNotification({
            title:
              "Password reset limit reached, please try again in a couple hours.",
          });
        } else {
          sendSuccessNotification({ title: "Email has been sent!" });
          setForgotPasswordEmailSent(true);
        }
      }
    }
  };

  if (forgotPasswordEmailSent) {
    return <Redirect to="/signin" />;
  }

  return (
    <ForgotPasswordContainer>
      <H1 center>Forgot Password</H1>
      <InputContainer>
        <Input
          label="Account Email"
          name="email"
          onChange={updateField}
          placeholder="What's your email?"
          type="email"
          value={field.email}
        />
        <Button
          disabled={!isValidEmail(field.email)}
          onClick={requestPasswordRecovery}
        >
          {!loading && <span>Send Recovery Email</span>}
          {loading && <LoadingSpinner />}
        </Button>
        <LinkButton
          id="signin"
          to={{ pathname: "/signin", state: { email: field.email } }}
          text
        >
          Back to Sign In
        </LinkButton>
      </InputContainer>
    </ForgotPasswordContainer>
  );
}

export default ForgotPassword;
