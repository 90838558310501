import {
  Button,
  Card,
  Flex,
  HStack,
  Heading,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "sonner";
import {
  BillingGroupMember,
  billingIntervalMap,
  deleteBillingElement,
  deleteBillingGroupMember,
  getBillingGroupMember,
} from "src/shared/api";
import { ModalAddElement } from "src/shared/components/ModalAddElement";
import { ModalAreYouSure } from "src/shared/components/ModalAreYouSure";
import { PageLayout } from "src/shared/components/PageLayout";
import { useModal } from "src/shared/context/ModalProvider";

export const TeamMemberDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { openModal, closeModal } = useModal();
  const [loading, setLoading] = useState(true);
  const [billingGroupMember, setBillingGroupMember] =
    useState<BillingGroupMember | null>(null);
  const history = useHistory();

  const fetchBillingGroupMember = useCallback(async () => {
    const response = await getBillingGroupMember(id);
    setBillingGroupMember(response);
    setLoading(false);
  }, [id]);

  const deleteBillingElementAndFetch = async (elementId: number) => {
    try {
      await deleteBillingElement(elementId);
      await fetchBillingGroupMember();
      closeModal();
      toast.success("Element removed successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to remove element");
    }
  };

  const deleteBillingGroupMemberAndRedirect = async (id: number) => {
    try {
      await deleteBillingGroupMember(id);
      closeModal();
      history.push(`/groups/${billingGroupMember?.billingGroupId}`);
      toast.success("Member removed successfully");
    } catch (error) {
      console.error(error);
      toast.error(
        "Failed to remove member: remove all subscription elements first"
      );
    }
  };

  useEffect(() => {
    fetchBillingGroupMember();
  }, [fetchBillingGroupMember]);

  if (loading) {
    return (
      <PageLayout title="Member Detail">
        <Spinner />
      </PageLayout>
    );
  }

  if (!billingGroupMember?.id) {
    return (
      <PageLayout title="Member Detail">
        <Flex justifyContent="center" w="full" p="4">
          Member not found
        </Flex>
      </PageLayout>
    );
  }

  return (
    <PageLayout
      title={`${billingGroupMember?.user?.firstName} ${billingGroupMember?.user?.lastName}`}
      actions={
        <Button
          size="sm"
          colorScheme="red"
          onClick={() =>
            openModal(
              <ModalAreYouSure
                action={() => deleteBillingGroupMemberAndRedirect(parseInt(id))}
              />
            )
          }
        >
          Delete Member
        </Button>
      }
    >
      <Flex>User ID: {billingGroupMember?.userId}</Flex>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="sm" mt="6">
          Subscription Elements
        </Heading>
        <Button
          ml="4"
          size="sm"
          onClick={() =>
            openModal(
              <ModalAddElement
                billingGroupMember={billingGroupMember}
                onSave={fetchBillingGroupMember}
              />
            )
          }
        >
          Add Subscription Element
        </Button>
      </Flex>
      <VStack mt="4">
        {billingGroupMember?.billingElements.map((element) => (
          <Card key={element.id} w="full" border="2px solid gray" p="4">
            <VStack gap="2" width="full" alignItems="start">
              <HStack justify="space-between" width="full">
                <Heading size="sm">
                  {element.label} ({billingIntervalMap[element.interval]})
                </Heading>
                <Button
                  size="xs"
                  colorScheme="red"
                  onClick={() =>
                    openModal(
                      <ModalAreYouSure
                        action={() => deleteBillingElementAndFetch(element.id)}
                      />
                    )
                  }
                >
                  Remove
                </Button>
              </HStack>
              <Flex gap="4">
                <Flex>{element.lineAllowance} line allowance</Flex>
                <Flex>
                  Overage lines billed{" "}
                  {billingIntervalMap[element.overageInterval].toLowerCase()}
                </Flex>
              </Flex>
            </VStack>
          </Card>
        ))}

        {billingGroupMember?.billingElements.length === 0 && (
          <Flex justifyContent="center" w="full" p="4">
            No subscription elements found
          </Flex>
        )}
      </VStack>
    </PageLayout>
  );
};
