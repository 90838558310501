import { Auth } from "aws-amplify";
import { Env } from ".";

const constants: Env = {
  AWS: {
    Auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_46mYGV5eu",
      userPoolWebClientId: "3ra8q9sv25bhequjj05dsitfpl",
      identityPoolId: "us-east-1:2134bc92-3823-41e7-8b1e-dfbbbb0e03cd",
      authenticationFlowType: "USER_PASSWORD_AUTH",
      mandatorySignIn: true,
    },
    API: {
      endpoints: [
        {
          name: "SCRIBE_API",
          endpoint: "https://krjcucfcl8.execute-api.us-east-1.amazonaws.com",
          custom_header: async (h) => {
            return {
              Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
      ],
    },
  },
  SENTRY: {
    DSN: "",
  },
};

export default constants;
