import { Box, HStack, Heading } from "@chakra-ui/react";

interface PageLayoutProps {
  title: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

export function PageLayout({ title, children, actions }: PageLayoutProps) {
  return (
    <Box p="6">
      <HStack
        justifyContent="space-between"
        width="full"
        alignItems="center"
        mb="8"
      >
        <Heading size="md">{title}</Heading>
        {actions}
      </HStack>
      {children}
    </Box>
  );
}
