import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider from "../shared/context/AuthProvider";
import Layout from "../shared/components/Layout";
import { GlobalStyle } from "./index.styles";
import NotificationProvider, {
  NotificationContainer,
} from "../shared/context/NotificationProvider";
import ModalProvider, { ModalContainer } from "../shared/context/ModalProvider";
import { Toaster } from "sonner";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <Router>
      <ChakraProvider>
        <GlobalStyle />
        <AuthProvider>
          <ModalProvider>
            <NotificationProvider>
              <Layout />
              <ModalContainer />
              <NotificationContainer />
              <Toaster richColors position="top-center" />
            </NotificationProvider>
          </ModalProvider>
        </AuthProvider>
      </ChakraProvider>
    </Router>
  );
}

export default App;
