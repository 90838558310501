import { Flex, HStack, Spinner, Button as CButton } from "@chakra-ui/react";
import { VisibilityState, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { MdOpenInNew } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "sonner";
import {
  BillingGroup,
  BillingGroupMember,
  billingIntervalMap,
  deleteBillingGroup,
  getBillingGroup,
  listBillingGroupMembers,
} from "src/shared/api";
import { Button } from "src/shared/components/Button";
import { ModalAddGroupMember } from "src/shared/components/ModalAddGroupMember";
import { ModalAreYouSure } from "src/shared/components/ModalAreYouSure";
import { PageLayout } from "src/shared/components/PageLayout";
import { Table } from "src/shared/components/Table";
import { SearchProvider } from "src/shared/components/Table/SearchContext";
import { useModal } from "src/shared/context/ModalProvider";
import { makeStripeCustomerLink } from "src/shared/utils";

export type BillingGroupMemberRow = {
  id: string;
  name: string;
  subscriptionElements: string;
  userId: string;
  billingGroupId: string;
  actions: string;
};

export const TeamDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { openModal, closeModal } = useModal();
  const [billingGroup, setBillingGroup] = useState<BillingGroup | null>(null);
  const [billingGroupMembers, setBillingGroupMembers] = useState<
    BillingGroupMember[]
  >([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const teamRows = useMemo<BillingGroupMemberRow[]>(() => {
    return billingGroupMembers?.map((billingGroupMember) => ({
      id: billingGroupMember.id.toString(),
      name: `${billingGroupMember.user?.firstName || ""} ${
        billingGroupMember.user?.lastName || ""
      }`,
      userId: billingGroupMember.userId?.toString() || "",
      subscriptionElements:
        billingGroupMember.billingElements?.length > 0
          ? billingGroupMember.billingElements
              ?.map(
                (element) =>
                  `${element.label} (${billingIntervalMap[element.interval]})`
              )
              .join(", ")
          : "None",
      billingGroupId: billingGroupMember.billingGroupId.toString(),
      actions: "",
    }));
  }, [billingGroupMembers]);

  useEffect(() => {
    (async () => {
      const billingGroupResponse = await getBillingGroup(id);
      setBillingGroup(billingGroupResponse);
      const response = await listBillingGroupMembers(id);
      setBillingGroupMembers(response.data);
      setLoading(false);
    })();
  }, [id]);

  const deleteBillingGroupAndRedirect = async (id: number) => {
    try {
      await deleteBillingGroup(id);
      closeModal();
      history.push(`/groups`);
      toast.success("Group deleted successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to remove group");
    }
  };

  const columnHelper = createColumnHelper<BillingGroupMemberRow>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        minSize: 150,
        cell: (cell) => (
          <Link
            to={`/groups/${cell.row.original.billingGroupId}/members/${cell.row.original.id}`}
          >
            <Flex alignItems="center" gap="1" fontWeight="bold" noOfLines={1}>
              {cell.getValue()}
            </Flex>
          </Link>
        ),
      }),
      columnHelper.accessor("userId", {
        header: "User ID",
        minSize: 100,
      }),
      columnHelper.accessor("subscriptionElements", {
        header: "Subscription Elements",
        minSize: 150,
        cell: (cell) => <Flex noOfLines={1}>{cell.getValue()}</Flex>,
      }),
      columnHelper.accessor("actions", {
        header: "Actions",
        minSize: 150,
        cell: (cell) => (
          <Flex justifyContent="flex-end">
            <Link
              to={`/groups/${cell.row.original.billingGroupId}/members/${cell.row.original.id}`}
            >
              Edit
            </Link>
          </Flex>
        ),
      }),
    ],
    [columnHelper]
  );

  const initialColumnVisibility: Record<keyof BillingGroupMemberRow, boolean> &
    VisibilityState = {
    id: false,
    name: true,
    subscriptionElements: true,
    userId: true,
    billingGroupId: false,
    actions: true,
  };

  if (loading) {
    return (
      <PageLayout title="Group Detail">
        <Spinner />
      </PageLayout>
    );
  }
  return (
    <SearchProvider>
      <Table
        title={billingGroup?.name}
        data={teamRows}
        loading={loading}
        columns={columns as any}
        rightActions={
          <HStack>
            {(billingGroupMembers?.length || 0) === 0 && (
              <CButton
                onClick={() =>
                  openModal(
                    <ModalAreYouSure
                      action={() => deleteBillingGroupAndRedirect(parseInt(id))}
                    />
                  )
                }
                size="sm"
                colorScheme="red"
              >
                Delete Group
              </CButton>
            )}
            <Button
              onClick={() =>
                openModal(<ModalAddGroupMember billingGroup={billingGroup} />)
              }
              secondary
              size="sm"
            >
              Add Member
            </Button>
            <Button
              onClick={() =>
                window.open(
                  makeStripeCustomerLink(billingGroup?.stripeCustomerId || ""),
                  "_blank"
                )
              }
              secondary
              size="sm"
            >
              <Flex align="center" gap="1">
                Open Stripe <MdOpenInNew />
              </Flex>
            </Button>
          </HStack>
        }
        initialColumnVisibility={initialColumnVisibility}
      />
    </SearchProvider>
  );
};
