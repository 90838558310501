import {Amplify, Auth} from 'aws-amplify'
import env from './envConfig'

const setupAwsAmplify = () => {
  Amplify.configure(env?.AWS)
}

export async function checkIsAdmin () {
  const groups = (await Auth.currentSession()).getIdToken().payload['cognito:groups']
  return groups?.includes("Admin")
}

export default setupAwsAmplify
