import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import Input from "../Input";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BillingGroup, createBillingGroupMember } from "src/shared/api";
import { toast } from "sonner";

export const ModalAddGroupMember = ({
  billingGroup,
}: {
  billingGroup: BillingGroup | null;
}) => {
  const { closeModal } = useModal();
  const history = useHistory();
  const [userId, setUserId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const title = "Add Group Member";

  const createGroup = async () => {
    if (!userId || !billingGroup?.id) return;

    try {
      setLoading(true);
      const billingGroupMember = await createBillingGroupMember(
        parseInt(userId),
        billingGroup.id
      );
      closeModal();
      history.push(
        `/groups/${billingGroupMember.billingGroupId}/members/${billingGroupMember.id}`
      );
      toast.success("Member created successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to create member");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={title}
      dialogue={{
        primary: {
          onClick: createGroup,
          title,
          autoFocus: true,
          loading,
        },
        secondary: { onClick: closeModal },
      }}
    >
      <Input
        value={userId}
        type="text"
        placeholder="User ID"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            createGroup();
          }
        }}
        onChange={(e) => setUserId(e.target.value)}
      />
    </Modal>
  );
};
