import { useState } from "react";
import Input from "src/shared/components/Input";
import { Error } from "src/shared/components/Text";
import {
  SignInContainer,
  ButtonContainer,
  InputContainer,
  LogoWrapper,
} from "./index.styles";
import { Button, LinkButton } from "src/shared/components/Button";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAuth } from "src/shared/context/AuthProvider";
import { log } from "src/shared/logger";
import { EventKind } from "src/shared/events";
import { formatLowercase } from "src/shared/formatters";
import { ReactComponent as Logo } from "src/shared/assets/welcome-logo.svg";
import { getEmployee } from "src/shared/api";

function SignIn() {
  const [field, setField] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [signedInError, setSignedInError] = useState(false);
  const [noAdminAccessError, setNoAdminAccessError] = useState(false);
  const { currentUser, signIn } = useAuth();

  const updateField = (event) => {
    setField({ ...field, [event.target.name]: event.target.value });
  };

  const isFormValid = () => {
    const fields = Object.keys(field);
    const hasAllFieldsFilled = fields.every(
      (fieldName) => field[fieldName] !== ""
    );
    return hasAllFieldsFilled;
  };

  const verifyIsPortalUser = async (user) => {
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    const employee = await getEmployee();

    if (!groups?.includes("Employee") || !employee?.id) {
      await Auth.signOut();
      setLoading(false);
      setNoAdminAccessError(true);
      return false;
    }
    return true
  };

  const requestCognitoLogin = async () => {
    if (isFormValid()) {
      setLoading(true);
      try {
        Auth.configure({
          storage: localStorage
        });
        const user = await Auth.signIn(
          formatLowercase(field.email),
          field.password
        );
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          await Auth.completeNewPassword(
            user,
            "ScribeTest123!", // TODO: Change this to a dynamic value
            user.challengeParam.requiredAttributes
          );
        }
        if (!(await verifyIsPortalUser(user))) return;
        setSignedInError(false);
        await signIn(user);
        log(EventKind.UserSignedIn);
        setLoading(false);
      } catch (error) {
        await Auth.signOut();
        setLoading(false);
        setSignedInError(true);
      }
    }
  };

  const enterSubmit = (event) => {
    if (event.key === "Enter" && isFormValid()) {
      requestCognitoLogin();
    }
  };

  if (currentUser && !loading) {
    return <Redirect to="/" />;
  }

  return (
    <SignInContainer>
      <InputContainer>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Input
          label="Account Email"
          name="email"
          onChange={updateField}
          onKeyPress={enterSubmit}
          placeholder="What's your email?"
          type="email"
          value={field.email || ""}
        />
        <Input
          label="Password"
          name="password"
          onChange={updateField}
          onKeyPress={enterSubmit}
          placeholder="Pssst, what’s your password?"
          type="password"
          value={field.password || ""}
        />
        {signedInError && (
          <Error>Sign in unsuccessful, incorrect username or password.</Error>
        )}
        {noAdminAccessError && (
          <Error>
            You do not have access to this site,
            <br /> contact support for help.
          </Error>
        )}
      </InputContainer>
      <ButtonContainer>
        <Button
          disabled={!isFormValid()}
          id="signin"
          onClick={requestCognitoLogin}
          loading={loading}
          style={{
            width: "100%",
            justifyContent: "center",
            fontSize: "1.1rem",
          }}
        >
          Sign In
        </Button>
      </ButtonContainer>
      <LinkButton
        id="forgotpassword"
        to={{ pathname: "/forgotpassword", state: { email: field.email } }}
        inline
        style={{ marginTop: "0.5rem"}}
        variant="ghost"
      >
        Forgot Password?
      </LinkButton>
    </SignInContainer>
  );
}

export default SignIn;
