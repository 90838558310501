const formatLowercase = (string = '') => {
  return string.toLowerCase()
}

const formatPhoneNumber = (number) => {
  if (!number) return ''
  const clean = number.replace(/\D/g, '')
  const match = clean.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return number
}

const formatTimeCondensed = (totalMinutes: number): string => {
  const minutesPerDay = 1440; // 24 hours * 60 minutes
  const minutesPerHour = 60;

  let result = "";

  const days = Math.floor(totalMinutes / minutesPerDay);
  if (days > 0) {
    result += `${days}d `;
  }

  const hours = Math.floor((totalMinutes % minutesPerDay) / minutesPerHour);
  if (hours > 0) {
    result += `${hours}h `;
  }

  const minutes = totalMinutes % minutesPerHour;
  if (minutes > 0) {
    result += `${minutes}m `;
  }

  return result.trim();
}

export {
  formatTimeCondensed,
  formatPhoneNumber,
  formatLowercase
}
