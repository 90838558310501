import Sidebar from '../Sidebar'
import LoadingSpinner from '../LoadingSpinner'
import { StyledLayout, StyledPage, StyledApp } from './index.styles'
import { useAuth } from 'src/shared/context/AuthProvider'
import { PrivateRoutesForApp, PublicRoutesForApp } from 'src/shared/routes'
import { colors } from 'src/shared/colors'

function Layout () {
  const { loading, currentUser } = useAuth()

  if (loading) {
    return (
      <StyledLayout data-testid="loading-layout" center>
        <LoadingSpinner backColor={colors.BLUE} frontColor={colors.BLUE_200} size={60} />
      </StyledLayout>
    )
  }

  if (!currentUser) {
    return <PublicRoutesForApp />
  }

  return (
    <StyledApp>
      <StyledLayout>
        <Sidebar data-testid="sidebar" />
        <StyledPage>
          <PrivateRoutesForApp />
        </StyledPage>
      </StyledLayout>
    </StyledApp>
  )
}

export default Layout
