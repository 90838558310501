import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import LoadingSpinner from "src/shared/components/LoadingSpinner";
import { isValidPassword } from "src/shared/validators";
import {
  SpinnerContainer,
  ForgotPasswordContainer,
  InputContainer,
} from "./index.styles";
import { H1, Error, AnchorLink } from "src/shared/components/Text";
import Input from "src/shared/components/Input";
import { Button } from "src/shared/components/Button";
import { useNotification } from "src/shared/context/NotificationProvider";
import { colors } from "src/shared/colors";
import { log } from "src/shared/logger";
import { EventKind } from "src/shared/events";
import { formatLowercase } from "src/shared/formatters";
import { useAuth } from "src/shared/context/AuthProvider";

function SetPassword(props) {
  const {
    email_address: email,
    confirmation_code: confirmationCode,
    setup_account: setupAccount,
  } = {
    email_address: "",
    confirmation_code: "",
    setup_account: false,
  };

  const initialFieldState = { password: "", passwordConfirm: "" };

  const [field, setField] = useState(initialFieldState);
  const [fieldError, setFieldError] = useState(initialFieldState);
  const [confirmationCodeExpired, setConfirmationCodeExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [passwordUpdateFailed, setPasswordUpdateFailed] = useState(false);
  const [passwordUpdateSucceeded, setPasswordUpdateSucceeded] = useState(false);
  const { currentUser, signOut } = useAuth();
  const { sendSuccessNotification, sendErrorNotification } =
    useNotification();

  const updateField = (event) => {
    setField({ ...field, [event.target.name]: event.target.value });
  };

  const validateFieldOnKeyUp = (event) => {
    if (fieldError[event.target.name]) {
      validateField(event);
    }
  };

  const validateField = (event) => {
    if (event.target.value === "") {
      setFieldError({ ...fieldError, [event.target.name]: "" });
    } else if (
      !fieldErrorValidators[event.target.name].validator(event.target.value)
    ) {
      setFieldError({
        ...fieldError,
        [event.target.name]: fieldErrorValidators[event.target.name].error,
      });
    } else {
      setFieldError({ ...fieldError, [event.target.name]: "" });
    }
  };

  const fieldErrorValidators = {
    password: {
      error: "Invalid password, must be 7 characters or longer",
      validator: isValidPassword,
    },
    passwordConfirm: {
      error: "Invalid password, both passwords must match",
      validator: (passwordConfirm) => field.password === passwordConfirm,
    },
  };

  const isFormValid = () => {
    const fields = Object.keys(field);
    const hasAllFieldsFilled = fields.every(
      (fieldName) => field[fieldName] !== ""
    );
    return hasAllFieldsFilled;
  };

  const requestCognitoUser = async () => {
    try {
      const user = await Auth.signIn(formatLowercase(email), confirmationCode);
      setCognitoUser(user);
    } catch (error) {
      setConfirmationCodeExpired(true);
      sendErrorNotification({
        title:
          "Failed to activate account. Contact support@mobileassistant.us for help.",
      });
    }
  };

  const requestUpdatePassword = async () => {
    if (isFormValid()) {
      setLoading(true);

      try {
        if (cognitoUser) {
          await Auth.completeNewPassword(cognitoUser, field.password);
        } else {
          await Auth.forgotPasswordSubmit(
            formatLowercase(email),
            confirmationCode,
            field.password
          );
        }
        setLoading(false);
        setPasswordUpdateSucceeded(true);
        log(EventKind.UserResetPassword);
      } catch (error) {
        setLoading(false);
        setPasswordUpdateFailed(true);

        if ((error as any)?.code === "ExpiredCodeException") {
          setConfirmationCodeExpired(true);
          sendErrorNotification({
            title:
              "Reset password link expired. Please request a new link and try again.",
          });
        } else {
          sendErrorNotification({
            title: "Password failed to reset. Please try resetting again.",
          });
        }
      }
    }
  };

  if (currentUser) {
    signOut();
  }

  if (!email || !confirmationCode || confirmationCodeExpired) {
    return <Redirect to={{ pathname: "/forgotpassword", state: { email } }} />;
  }

  if (passwordUpdateSucceeded) {
    sendSuccessNotification({ title: "Password updated successfully!" });
    return <Redirect to={{ pathname: "/signin", state: { email } }} />;
  }

  if (setupAccount && !cognitoUser) {
    requestCognitoUser();
    return (
      <SpinnerContainer>
        <LoadingSpinner
          backColor={colors.BLUE}
          frontColor={colors.BLUE_200}
          size={60}
        />
      </SpinnerContainer>
    );
  }

  return (
    <ForgotPasswordContainer>
      <H1 center>Set New Password</H1>
      <InputContainer>
        <Input
          label="Account Email"
          name="email"
          type="email"
          value={email}
          readOnly
        />
        <Input
          autoFocus={true}
          error={fieldError.password}
          label="New Password"
          name="password"
          onBlur={validateField}
          onChange={updateField}
          onKeyUp={validateFieldOnKeyUp}
          placeholder="Something Secure (7+ Characters)"
          type="password"
          value={field.password}
        />
        <Input
          error={fieldError.passwordConfirm}
          label="Confirm New Password"
          name="passwordConfirm"
          onBlur={validateField}
          onChange={updateField}
          onKeyUp={validateFieldOnKeyUp}
          placeholder="Confirm Your Password"
          type="password"
          value={field.passwordConfirm}
        />
        {passwordUpdateFailed && (
          <Error>
            Password update failed. Please try again or contact us at{" "}
            <AnchorLink href="mailto:support@mobileassistant.us">
              support@mobileassistant.us
            </AnchorLink>{" "}
            and we'll help you out!
          </Error>
        )}
        <Button
          disabled={!isFormValid()}
          id="resetpassword"
          onClick={requestUpdatePassword}
        >
          {!loading && <span>Update My Password</span>}
          {loading && <LoadingSpinner />}
        </Button>
      </InputContainer>
    </ForgotPasswordContainer>
  );
}

export default SetPassword;
