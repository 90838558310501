import { createGlobalStyle } from 'styled-components'
import { colors } from '../shared/colors'

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        font-family: "Inter", sans-serif;
        font-weight: 500;
    }

    body {
        padding: 0;
        margin: 0;
    }

    body, html, #root {
        background-color: ${colors.WHITE};
        height: 100%;
    }

    #root {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    a {
        color: ${colors.BLUE};
        text-decoration: none;
        transition: color 0.2s ease;
    }

    a:hover {
        color: ${colors.BLUE_200} !important;
    }
    
    nav a:hover {
        color: ${colors.BLACK} !important;
    }
`
