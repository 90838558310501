import styled from "styled-components";
import { media } from "src/shared/breakpoints";
import { colors } from "src/shared/colors";

export const SIDEBAR_WIDTH = "225px";

export const SidebarWrapper = styled.div`
  height: 100%;
  max-width: ${SIDEBAR_WIDTH};
  z-index: 999;

  ${media.tablet} {
    height: 4rem;
    max-width: 100%;
    width: 100%;
    bottom: 0;
    position: fixed;
    z-index: 500;
  }

  nav {
    font-style: normal;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: ${colors.WHITE};
    border-right: 2px solid ${colors.GREY_200};
    justify-content: space-between;
    align-items: flex-start;

    ${media.tablet} {
      border-right: none;
      flex-direction: row;
      border-top: 2px solid ${colors.GREY_200};
      justify-content: center;
      height: 4rem;
      width: 100vw;
    }
  }

  nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    width: 100%;
    margin-top: 2rem;

    ${media.tablet} {
      margin: 0;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-evenly;
    }

    li {
      display: flex;
      margin: 0;
      transition: 0.25s ease;
      width: 100%;

      ${media.tablet} {
        margin: 0 0.5rem;
      }
    }

    li a {
      opacity: 0.5;
      width: 100%;
      font-weight: 600;

      :hover {
        opacity: 1;
      }

      ${media.tablet} {
        flex-direction: column;
        font-size: 0.7rem;
        background-color: none;
        width: 4rem;
        padding: 0.3rem;
        width: 3.5rem;
        height: 3.5rem;

        svg {
          margin: 0;
          margin-bottom: 0.1rem;
        }

        span {
          margin-left: 0 !important;
        }

        :hover {
          background: none;
        }
      }
    }
  }

  .toggleButton {
    ${media.tablet} {
      display: none;
    }
  }

  .minimizeButton {
    display: flex;
    flex-flow: row;
    width: max-content;

    :hover {
      box-shadow: 0 2px 7px ${colors.BLACK_A40};
      opacity: 1;

      svg {
        margin-right: 0.3rem;
      }
    }

    ${media.tablet} {
      display: none;
    }

    :hover {
      ::after {
        content: "Expand Menu";
      }
    }
  }

  .ExpandedNav {
    padding: 1.5rem;
    padding-top: 1rem;
    min-width: ${SIDEBAR_WIDTH};

    @media (max-width: 768px) {
      padding-right: 1rem;
    }
    ${media.print} {
      display: none;
    }
  }

  .MinimizedNav {
    width: calc(53px + 2rem - 2px);

    ${media.tablet} {
      width: 100vw;
    }

    .talkItTitle {
      a {
        padding: 0;

        h1 {
          display: none;
        }
      }
    }
  }
`;

export const TalkItTitle = styled.div`
  display: flex;
  margin-top: -0.25rem;

  ${media.tablet} {
    display: none;
  }

  a {
    transition: padding 0.2s ease;
    text-decoration: none;
    display: inline-flex;
    margin: 0;

    h1 {
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 120%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      margin: 0 0 0 0.5rem;
      color: ${colors.BLUE};
    }

    span {
      font-size: 0.9rem;
      color: ${colors.GREY};
    }

    svg {
      width: 2rem;
    }
  }
`;

export const NavItem = styled.li`
    .active {
        opacity: 1;
    }
    
    a {
        color: ${colors.BLACK} !important;
    }

    .expandedButton {
        ${media.minTablet} {
            margin: 0.25rem 0;

            span {
                font-size: 1.15rem;
            }
        }

        ${media.desktop} {
            margin: 0.25rem 0;

            span {
                font-size: 1.15rem;
            }
        }
    }

    .iconButton {
        justify-content: flex-start;
        margin: 0.25rem 0;

        svg {
            margin: 0;
        }

        ${media.tablet} {
            flex-direction: column;
            font-size: .7rem;
            background-color: none;
            width: 4rem;

            ::after {
                content: "${(props) => props.title}"
            }

            svg {
                margin: 0;
                margin-bottom: .1rem;
            }
        }

        :hover {
            background: ${colors.GREY_100};
            box-shadow: 0 2px 7px ${colors.BLACK_A40};
            opacity: 1;

            ::after {
                content: "${(props) => props.title}"
            }

            ${media.desktop} {
                ::after {
                    font-size: 1.15rem;
                    content: "${(props) => props.title}"
                }
            }

            svg {
                margin-right: .3rem;
            }

            ${media.tablet} {
                flex-direction: column;
                font-size: .7rem;
                background-color: none;
                width: 4rem;
                background: none;
                color: ${colors.BLACK};
                box-shadow: 0 0 0 ${colors.TRANSPARENT};

                svg {
                    margin: 0;
                    margin-bottom: .1rem;
                    path {
                        fill: ${colors.BLACK}; !important;
                    }
                }

                :hover {
                    svg {
                        path {
                            fill: ${colors.BLACK} !important;
                        }
                    } 
                }
            }
        }
    }
`;
