import {
  Icon,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
} from "@chakra-ui/react";
import { useSearch } from "./SearchContext";
import { MdSearch } from "react-icons/md";

export const SearchInput = (props: InputGroupProps) => {
  const { setGlobalQuery, globalQuery } = useSearch();

  return (
    <InputGroup {...props}>
      <InputLeftElement pointerEvents="none">
        <Icon as={MdSearch} opacity="0.7" fontSize="sm" mt="-0.5rem" ml="-0.1rem" />
      </InputLeftElement>
      <Input
        size="sm"
        placeholder="Search..."
        transition="all 0.15s"
        width={globalQuery ? "300px" : "200px"}
        _placeholder={{ opacity: "0.7" }}
        _focus={{
          width: "300px",
        }}
        borderRadius="6px"
        value={globalQuery}
        onChange={(e) => setGlobalQuery?.(e.target.value)}
      />
    </InputGroup>
  );
};