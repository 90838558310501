import { useModal } from "src/shared/context/ModalProvider";
import Modal from "../Modal";
import { Text } from "@chakra-ui/react";
import { useState } from "react";

export const ModalAreYouSure = ({ action }: { action: () => void }) => {
  const { closeModal } = useModal();
  const [loading, setLoading] = useState(false);

  const takeAction = async () => {
    setLoading(true);
    try {
      await action();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const title = "Are you sure you want to do this?";

  return (
    <Modal
      title={title}
      dialogue={{
        primary: {
          onClick: takeAction,
          title: "Yes, I'm sure",
          autoFocus: true,
          loading,
        },
        secondary: { onClick: closeModal },
      }}
    >
      <Text>This action cannot be undone.</Text>
    </Modal>
  );
};
