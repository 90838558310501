import styled, { css } from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { colors } from "src/shared/colors";

export interface StyledButtonProps {
  small?: boolean;
  secondary?: boolean;
  fab?: boolean;
  inverse?: boolean;
  text?: boolean;
  disabled?: boolean;
  inline?: boolean;
  banner?: boolean;
  danger?: boolean;
}

const sharedButtonStyles = css<StyledButtonProps>`
  position: relative;
  overflow: hidden;
  align-items: center;
  font-size: 0.9rem;
  font-weight: bold;
  outline: none;
  text-align: center;
  padding: ${(props) => (props.small ? ".35rem" : "0.5rem 1.5rem")};
  background-color: ${(props) =>
    props.secondary ? colors.BLACK : colors.BLUE};
  color: ${colors.WHITE};
  border-radius: 10px;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.25s ease;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  border: none;
  justify-content: center;
  
  .MuiLinearProgress-bar {
    background-color: #0000001f;
  }

  &:hover {
    color: ${colors.WHITE};
    opacity: 0.8;
  }

  &:visited {
    color: ${colors.WHITE};
  }
  
  ${(props) => props.disabled && `
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      opacity: 1;
    }
  `}
  
  ${(props) => props.inline && `
    background-color: transparent;
    color: ${colors.BLACK} !important;
    padding: 0;
  `}
  
  ${(props) => props.secondary && `
    background-color: ${colors.GREY_200};
    color: ${colors.BLACK};
    
    &:hover {
      background-color: ${colors.GREY_300};
      color: ${colors.BLACK};
    }
  `}
  
  &:focus-visible {
    box-shadow: 0 0 0 3px ${colors.GREY_800};
  }
`;

export const StyledButton = styled.button`
  ${(props) => sharedButtonStyles}
`;

export const StyledLinkButton = styled(
  ({
    text,
    inverse,
    inline,
    small,
    disabled,
    secondary,
    isActive,
    banner,
    to,
    ...restProps
  }) => <Link to={to} {...restProps} />
)`
  ${(props) => sharedButtonStyles}
`;

export const StyledNavLinkButton = styled(
  ({
    text,
    inverse,
    inline,
    small,
    disabled,
    secondary,
    isActive,
    banner,
    to,
    ...restProps
  }) => <NavLink to={to} activeClassName={"active"} {...restProps} />
)`
  display: flex;
  align-items: center;
`;
